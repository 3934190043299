<template>
  <div style="margin: 0;padding: 0">
    <div
        id="body"
        style="
        background-color: rgba(146,185,185,0.62);
        /*background-image: url(https://thumbs.dreamstime.com/b/web-street-map-town-background-gray-your-site-design-logo-app-ui-stock-vector-eps-192605597.jpg);*/
        overflow: hidden"
    />
    <div
        style="
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #2c3e50;
        position:absolute;
        top:500px;
        font-size: 15px;
        right: 20px;
        color:white;
"
        @click="pluse">
      +
    </div>
    <div style=" width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #2c3e50;
        position:absolute;
        top:560px;
        font-size: 15px;
        right: 20px;
        color:white;"
         @click="minus">-
    </div>
  </div>

</template>

<script>


import {SVG} from '@svgdotjs/svg.js'
import axios from "axios";
import {SERVER_HOST} from "@/config";
import PanZoom from 'panzoom'


export default {
  name: 'svgComponent',
  data() {
    return {
      element: null,
      map: null,
      fillGroup: 'rgba(255,255,255,0)',
      strokeGroup: 'rgba(44,61,101,0)',
      centerXMap: null,
      centerYMap: null,
      countBreake: 600,
      countStand: 0,
      countStandTitle: 0,
      draw: null,
      dataServer: null,
      standColor: 'grey',
      standStroke: 'white',
      standNameColor: 'white',
      standGroup: null,
      zoom: 1,
      deltaZoom: 1,
      deltaLeft: 0,
      deltaTop: 0,
      startScale: 0.3,
      mousePress: false
    }
  },
  computed(){

  },
  methods: {
    mousemove() {
      // if(this.mousePress){
      //   event.preventDefault();
      //
      //   // console.log(event)
      //   console.log(event.clientX, event.clientY);
      //   this.draw.viewbox(this.draw.viewbox().x+100,this.draw.viewbox().y+100,this.draw.viewbox().width,this.draw.viewbox().height)
      //   // this.map.move(event.clientX,event.clientY)
      // }


    },
    mousedown() {
      this.mousePress = true
      // console.log(e)
    },
    mouseup(e) {
      this.mousePress = false
      console.log(e)
    },
    pluse() {
      console.log(this.map.cx())
      console.log(this.map.cy())
      this.element.smoothZoom(this.draw.cx(), this.draw.cy(), 1.5)
      // console.log('pluse')
      // let centerCenter = {x: 0.5, y: 0.5};
      // this.element.transformOrigin(centerCenter)
      // let element = document.getElementById('map')

      // PanZoom(this.element,{
      //   transformOrigin:centerCenter
      // })
      // this.draw.animate({
      //   when: 'now',
      //   swing: true,
      // })
      //     .viewbox(
      //         this.draw.viewbox().x+this.draw.viewbox().width/4,
      //         this.draw.viewbox().y+this.draw.viewbox().height/4,
      //         this.draw.viewbox().width/2,
      //         this.draw.viewbox().height/2)
    },
    minus() {
      this.element.smoothZoom(this.draw.cx(), this.draw.cy(), 0.5)
      // this.draw.animate({
      //   when: 'now',
      //   swing: true,
      //
      // })
      //     .viewbox(
      //         this.draw.viewbox().x-this.draw.viewbox().width/2,
      //         this.draw.viewbox().y-this.draw.viewbox().height/2,
      //         this.draw.viewbox().width*2,
      //         this.draw.viewbox().height*2)
    },
    getCanvasJSONFromServer() {
      return axios
          .get(SERVER_HOST + '/v4/show/' + this.$route.params.code + '/interactive-map')
          .then((resp) => {
            return resp.data.data
          })
          .catch((error) => {
            this.loadingText = error.message
            return false
          })
    },
    clickStandFun(ind) {
      console.log(ind)
    },
    fun(i) {
      alert(i)
    }
  },
  mounted() {
    const width = window.innerWidth// ширина окна
    const height = window.innerHeight // высота окна

    this.draw = SVG()
        .addTo('#body')
        .size(width, height)


    this.centerXMap = this.draw.cx() // центр карты Х
    this.centerYMap = this.draw.cy() // центр карты Y

    const data = this.getCanvasJSONFromServer() // получаем данные с сервера

    data.then((res) => {
      this.map = this.standGroup = this.draw.group().attr('id', 'map')
      let parse = JSON.parse(res.mapView), standServer
          // , company, icon
          , floor

      console.log(parse)

      // icon = parse.objects.filter(el => el.slugGroup === 'icon')[0]?.objects || []
      standServer = parse.objects.filter(el => el.slugGroup === 'stand')[0]?.objects || []
      // company = parse.objects.filter(el => el.slugGroup === 'company')[0]?.objects || []

      floor = parse.objects.filter(el => el.slugGroup === 'floor')[0]?.objects || []

      addElement(floor, {draw: this.draw, standNameColor: this.standNameColor, map: this.map})
      addStand(standServer, {draw: this.draw, standColor:this.standColor,standNameColor: this.standNameColor, map: this.map, standStroke:this.standStroke})
      // addElement(icon, {draw: this.draw, standNameColor: this.standNameColor, map: this.map})
      // addElement(company, {draw: this.draw, standNameColor: this.standNameColor, map: this.map})



      function addStand(arrayElement, params) {
        for (let i = 0; i < arrayElement.length; i++) {
          let obj = arrayElement[i]
          if (obj.type === 'rect' && obj.isStand) {
            let rectStand = params.draw
                .rect(obj.width * obj.scaleX, obj.height * obj.scaleY)
                .attr({
                  id: 'stand_' + obj.event.value,
                  stroke: params.standStroke,
                  x: obj.left,
                  y: obj.top,
                  fill: params.standColor,
                  idStand: obj.event.value
                }).transform({
                  rotate: obj.angle,
                })

            let standText = standServer.filter((e) => e.event.value === obj.event.value && e.type === 'textbox')[0]
            standText = params.draw
                .text(standText.text)
                .attr({
                  x: standText.left,
                  y: standText.top,
                  fill: params.standNameColor
                }).font({
                  textAlign: standText.textAlign,
                  family: standText.fontFamily,
                  size: standText.fontSize * standText.scaleX,
                  lineHeight: 1.16
                }).transform({
                  origin: [standText.left, standText.top - 10],
                  translateX: 0,
                  translateY: 15,
                  rotate: standText.angle
                })

            let group = params.draw.group()
            group.add(rectStand)
            group.add(standText)
            params.map.add(group)
          }
        }
      }


      function addElement(arrayElement, params) {
        for (let i = 0; i < arrayElement.length; i++) {
          let group = params.draw.group()
          let rect, text, image
          let obj = arrayElement[i]
          if (obj.type === 'rect') {
            rect = this.draw
                .rect(obj.width * obj.scaleX, obj.height * obj.scaleY)
                .attr({
                  id: 'stand_' + obj.event.value,
                  stroke: this.standStroke,
                  x: obj.left,
                  y: obj.top,
                  fill: this.standColor,
                  idStand: obj.event.value
                }).transform({
                  rotate: obj.angle,
                })
            group.add(rect)
          }

          if (obj.type === 'textbox') {

            text = params.draw
                .text(function (add) {
                  add.tspan('Lorem ipsum dolor sit amet ').newLine().dx(3)
                  add.tspan('consectetur').fill('#f06')
                  add.tspan('.')
                  add.tspan('Cras sodales imperdiet auctor.').newLine().dx(20)
                  add.tspan('Nunc ultrices lectus at erat').newLine().newLine()
                  add.tspan('dictum pharetra elementum ante').newLine()
                })
                .attr({
                  x: obj.left,
                  y: obj.top,
                  fill: params.standNameColor
                }).font({
                  width: obj.width * obj.scaleX,
                  height: obj.height * obj.scaleY,
                  textAlign: obj.textAlign,
                  family: obj.fontFamily,
                  size: obj.fontSize * obj.scaleX,
                  lineHeight: 1.2
                }).transform({
                  origin: [obj.left, obj.top - 10],
                  translateX: 0,
                  translateY: 15,
                  rotate: obj.angle
                })


            group.add(text)
          }
          if (obj.type === 'image') {
            console.log(obj)
            image = params.draw
                .image(obj.src)
                .attr({
                  width: obj.width * obj.scaleX,
                  height: obj.height * obj.scaleY,
                  x: obj.left,
                  y: obj.top,
                  fill: params.standNameColor
                }).transform({
                  // origin: [obj.left, obj.top - 10],
                  // translateX: 0,
                  // translateY: 15,
                  // rotate: obj.angle
                })
            group.add(image)
          }
          params.map.add(group)
        }
      }


      this.map.move(0, 0)
      this.draw.viewbox(0, 0, this.map.width(), this.map.height())

      let element = document.getElementById('map')
      this.element = PanZoom(element, {
        maxZoom: 1,
        minZoom: 0.08,
        bounds: true

        // onTouch: function(e) {
        //    // `e` - is current touch event.
        //   return false; // tells the library to not preventDefault.
        // },
        // onDoubleClick:function (){
        //    alert('boom')
        // }
      })

    })

  }
}

</script>

<style lang="scss" scoped>


.resizeBlock {
  z-index: 50;
  position: absolute;
  margin: 40vh 10px 0 0;
  right: 0;
}

.resize {
  width: 44px;
  height: 44px;
  margin-top: 5px;
}

.resize:hover {
  cursor: pointer;
}


.lfs-map-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //z-index: 10;

  &-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }
}

</style>
