import Vue from 'vue'

// import './plugins/axios'
import App from './App.vue'
import router from './router'
import { SVG } from '@svgdotjs/svg.js'
import '@svgdotjs/svg.draggable.js'
Vue.config.productionTip = false
Vue.use(SVG);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
